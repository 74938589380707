import React from "react";
import { Link, Head } from "@inertiajs/inertia-react";

export default function Error(props) {    
    return (
        <>
            <Head title="Erro - VENDII" />
            <div className="relative flex items-top justify-center min-h-screen bg-gray-100 dark:bg-gray-900 sm:items-center sm:pt-0">
                <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
                    <div className="text-center">
                        <i className="fas fa-bug text-red-500 text-8xl"></i>
                        <h1 className="ml-3 text-xl mt-4 font-bold">Oops! Algo deu errado.</h1>
                        <a href={route('auth.login')}>
                            <button className="mt-3 bg-red-500 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150">
                                Voltar ao site
                            </button>
                        </a>
                    </div>

                    <div className="mt-8 bg-white dark:bg-gray-800 overflow-hidden shadow sm:rounded-lg">
                        <div className="p-5">
                            <p><b>{props.status}</b> - {props.error}</p>                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
